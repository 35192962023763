<template>
  <div class="d-flex flex-column items-center">
    <section
      class="d-flex flex-column flex-md-row justify-center align-center my-6 action-complete"
    >
      <img
        src="../assets/images/mailbox.png"
        alt="mailbox"
        height="150px"
        class="mailbox"
      />
      <div class="text-xs-center text-md-left mx-4">
        <h2 class="text-h3 text-md-h2 font-weight-bold mb-2">
          Thank you for your letter to {{ selectedRep.name }}!
        </h2>
        <h5 class="text-h5 font-weight-regular">
          Your donation and letter are appreciated.
        </h5>
      </div>

      <!-- Area for progress bar? -->
    </section>
    <!-- TODO: Fill with rep info later on
    <section
      class="d-flex flex-column justify-center align-center my-6 action-complete"
    >
      <h4 class="text-h4">
        Want to send another letter for this campaign?
      </h4>
      <p class="text-h6 px-6">
        Here are other local representatives who will play key roles in deciding
        policy. Your letter ensures that they pay attention.
      </p>

      <div class="d-flex flex-row justify-center">
        <v-card
          v-for="(card, index) in cards"
          :key="index"
          class="mx-4"
        >
          <img
            :src="card.imgSrc"
            :alt="`Image of ${card.name}`"
            class="mailbox"
          >
          <p>{{ card.name }}</p>
        </v-card>
      </div>
    </section>
    -->
  </div>
</template>

<script lang="js">
// import RepresentativeCard from './RepresentativeCard.vue'

export default {
  name: 'ActionComplete',
  // components: { RepresentativeCard },
  props: [],
  data() {
    return {
      loading: true,
      email: '',
      amount: 0, // Will be in cents
      expectedDeliveryDate: '',
      congressMembers: []
    }
  },
  computed: {
    donationAmount() {
      return this.amount * 0.01
    },
    selectedRep() {
      return this.$store.state.selectedRep
    },
  },
  created () {
    const sessionId = this.$route.query.session_id

    // Retrieve letter details from state
    this.$store.dispatch('retrieveStateFromLocalStorage', sessionId)
      .catch((e) => {
        console.error(e.message)
        this.$router.push({ path: '/' })
      })

  },

}
</script>

<style scoped lang="less">
.mailbox {
  height: 150px;
}
</style>

<template>
  <div>
    <action-complete />
    <!--
    <cause-carousel />
    -->
  </div>
</template>

<script>
import ActionComplete from '@/components/ActionComplete.vue'
// import CauseCarousel from '@/components/CauseCarousel.vue'

export default {
  components: {
    ActionComplete
    // CauseCarousel
  }
}
</script>
